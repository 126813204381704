import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import './Slide.css'


const AutoplaySlider = withAutoplay(AwesomeSlider);

const Slide = () => {
  return (
    
    <div className='sildeContainer flex'>
      <AutoplaySlider style={{ "--slider-height-percentage": "100%","background-size": "cover","height": "530px","background-repeat": "no-repeat","background-position": "center"}}
        play={true}
        cancelOnInteraction={false} // should stop playing on user interaction
        interval={3000}
      >
        <div className='he' data-src={require('../../assets/images/slide/ugadi.png')} />
        <div className='he' data-src={require('../../assets/images/slide/1.jpeg')} />
        <div className='he' data-src={require('../../assets/images/slide/2.jpeg')} />
        <div className='he' data-src={require('../../assets/images/slide/3.jpeg')} />
        <div className='he' data-src={require('../../assets/images/slide/4.jpeg')} />
        <div className='he' data-src={require('../../assets/images/slide/5.jpeg')} />
        <div className='he' data-src={require('../../assets/images/slide/6.jpeg')} />
        <div className='he' data-src={require('../../assets/images/slide/7.jpeg')} />
        <div className='he' data-src={require('../../assets/images/slide/8.jpeg')} />
      </AutoplaySlider>
    </div>
  );
};


export default Slide;